.nav_wrap {
  // position: fixed;
  // top: 0;
  // left: 0;
  z-index: 2;
  width: 100%;
  height: 120px;
  background: rgba(0, 0, 0, 0);
  padding: 0 5%;
  width: 100%;

  .nav_wrap_title_type {
    font-size: 18px;
    font-weight: 400;
    color: #262626;
    line-height: 26px;
  }

  .nav_wrap_title {
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    height: 100%;
    font-size: 18px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;

    .nav_wrap_title_wrap {
      color: rgba(0, 0, 0, 0.65);
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 22px;

      .nav_wrap_title_content {
        color: #333333;
        font-size: 24px;
        text-align: center;
        font-weight: 700;
        font-style: normal;
        height: 42px;
        cursor: pointer;
        white-space: nowrap;
        font-kerning: normal;
        display: flex;

        img {
          height: 42px;
          // margin-right: 10px;
        }
      }

      .nav_wrap_title_line {
        margin: 0 24px;
        width: 1px;
        height: 34px;
        background: #A9A9A9;
      }
    }
  }

  .backLogin {
    width: 76px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid #2C68FF;
    font-size: 18px;
    color: #2C68FF;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.nav_wrap_nothing {
  display: none
}