.login_wrap_footer {
  // position: fixed;
  // bottom: 0;
  // left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #A9A9A9;
  height: 80px;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  // background: #f6f6f6;
  position:absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
  word-break: break-all;
  .footer-record-wrap{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .displayCenter{
    display: flex;
    justify-content: center !important;
    align-items: center;
    max-width: 90%;
    overflow-x: scroll;
  }
  .displayCenter::-webkit-scrollbar {
    display: none;
  }
}
