// @import "~antd/dist/antd.less";
@import "./theme/index.less";

#root {
  height: 100%;
  .App {
    height: 100%;
  }

  .fx {
    // flexbox布局
    &-flexbox {
      width: auto;
      display: flex;
      align-items: center;

      &-flexwrap {
        flex-wrap: wrap;
      }

      // justify-content
      &-center {
        justify-content: center;
      }

      &-between {
        justify-content: space-between;
      }

      &-around {
        justify-content: space-around;
      }

      &-right {
        justify-content: flex-end;
      }

      // align-items
      &-align-initial {
        align-items: initial;
      }

      &-align-end {
        align-items: flex-end;
      }

      &-align-start {
        align-items: flex-start;
      }

      // align-content
      &-content-around {
        align-content: space-around;
      }

      &-vertical {
        flex-direction: column;
        height: 100%;

        .fx-flexbox-item {
          width: 100%;
        }
      }

      &-item {
        flex: 1;

        &-start {
          align-self: flex-start;
          justify-self: flex-end;
        }

        &-center {
          align-self: center;
        }

        &-end {
          align-self: flex-end;
        }
      }
    }

    &-fl {
      float: left;
    }

    &-fr {
      float: right;
    }

    &-clearfix {
      &:before,
      &:after {
        content: " ";
        display: table;
      }

      &:after {
        clear: both;
      }
    }
  }
}
